
import React from "react"
import Layout from "../components/js/layout"
import SEO from "../components/js/seo"
import queryString from "query-string"

import lecturesData from "./../data/lectures.yaml"

import "../components/css/page/univ-dc.css"

class UnivDiscount extends React.Component {

  state = {
    code: '',
    title: '',
    link: ''
  }

  render () {

    return (
      <Layout page="univ-dc">
        <SEO title="얄코강좌 대학생 할인" />
        <section id="univDc">
          <h1>
            얄코강좌 대학생 할인
            <span role="img" aria-label="books">🎓</span>
          </h1>
          <h2>
            { this.state.title }
            {
              this.state.link && (
                <a href={ this.state.link} target="_blank" rel="noreferrer">
                  강의 바로가기
                </a>
              )
            }
          </h2>
          <div>
            <div className="info">
              이메일로 <strong>학생증 사진</strong> 등을 첨부하여 쿠폰을 요청하시면 <strong>쿠폰 번호</strong>를 보내드립니다.
              <p>
                사진 등 개인정보는 손으로 가리고 대학교 이름이 보이도록만 찍어 보내주시면 됩니다. 온라인 학생증 등 학생임을 보여줄 수 있는 다른 자료도 유효합니다.
              </p>
            </div>
            <h3>
              <span role="img" aria-label="">🎫</span>
              반값쿠폰 받기
            </h3>
            <span className="note">
              * 반값 쿠폰은 다른 강의에서도 받을 수 있습니다.
            </span>
            <dl>
              <dt>받는이</dt>
              <dd>yalco@yalco.kr</dd>
              <dt>제목</dt>
              <dd>대학생 쿠폰 요청 - { this.state.code.toUpperCase() }</dd>
              <dt>내용</dt>
              <dd>(학생증 사진 등 재학 증명용 이미지)</dd>
            </dl>

          </div>
        </section>
      </Layout>
    )
  }

  componentDidMount () {
    const code = queryString.parse(window.location.search).lecture
    const lecture = lecturesData.filter((lec) => {
      return lec.code === code
    })[0]

    this.setState(() => {
      return {
        code: lecture.code,
        title: lecture.title,
        link: lecture.link
      }
    })
  }

}

export default UnivDiscount